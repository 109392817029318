@import "../../assets/scss/variables.scss";

.supportersModal .modal-dialog {
  max-width: 618px;
}
.supportersModal {
  img {
    height: auto;
    margin-top: 32px;
  }
}

.support-head {
  margin-top: 44px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.supportersModal .modal-body {
  padding-top: 0;
}
