@import "./../../assets/scss/variables.scss";

$font-family_1: $SFPRODISPLAY;
$background-color_1: transparent;
$background-color_2: $BG_WHITE;

.checkboxMain1 {
  display: block;
  position: relative;

  input {
    border-bottom: 1px solid $BG_LIGHTGREY;
    display: none;

    h2 {
      margin-bottom: 30px;
      margin-top: 20px;
      color: $TEXT_TITLE;
    }
  }

  label {
    display: block;
    position: relative;
    cursor: pointer;
    font-family: $font-family_1;
    font-size: $FONT_16;
    margin: 0;
    padding: 8px 0px;
    color: $TEXT_GREY;
  }

  span {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.checked {
      i {
        position: relative;
        top: -2px;
        left: 0px;
        display: block;
        width: 5px;
        height: 11px;
        border-style: solid;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}