@import "./../../assets/scss/variables.scss";
$font-family_1: $SFPRODISPLAY;
$background-color_1: transparent;
$background-color_2: $BG_WHITE;

.checkboxMain {
  display: block;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:disabled {
      + {
        label {
          opacity: 0.5;
        }
      }
    }

    &:checked {
      + {
        label {
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 5.5px;
            width: 5px;
            height: 10px;
            border: solid $TEXT_GREY;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }

          &::before {
            background-color: $background-color_2;
            border: 1px solid $TEXT_GREY;
          }
        }
      }
    }
  }

  label {
    position: relative !important;
    cursor: pointer !important;
    font-family: $font-family_1;
    font-size: $FONT_14  !important;
    font-weight: 400 !important;
    margin: 0 !important;

    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: $background-color_1;
      border: 1px solid $TEXT_GREY;
      padding: 7px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 8px;
      border-radius: 3px;
      top: -1px;
    }
  }
}

@media screen and (max-width: 767px) {
  .checkboxMain {
    label {
      font-size: $FONT_12;
    }

    input {
      &:checked {
        + {
          label {
            &:after {
              position: absolute;
              top: 3px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .checkboxMain {
    label {
      font-size: $FONT_12;
    }

    input {
      &:checked {
        + {
          label {
            &:after {
              position: absolute;
              top: 3px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .checkboxMain {
    label {
      font-size: $FONT_12;
    }

    input {
      &:checked {
        + {
          label {
            &:after {
              position: absolute;
              top: 3px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .checkboxMain {
    label {
      font-size: $FONT_14;
    }

    input {
      &:checked {
        + {
          label {
            &:after {
              position: absolute;
              top: 3.5px;
            }
          }
        }
      }
    }
  }
}