@import "../../assets/scss/variables.scss";

.bookmarkWrap {
  .btn-wrap {
    button {
      &:not(:first-child) {
        padding: 10px 13px;

        .icon {
          margin-right: 0;
        }
      }
    }
  }

  .no_data {
    text-align: center;
    font-family: $SFPRODISPLAY;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 42px;
    color: $TEXT_PURPLE;
    width: 100%;
    padding-top: 25px;

    p {
      margin-top: 10px;
    }
  }
}