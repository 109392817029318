
@import "../../assets/scss/variables.scss";

.privacy-head span {
    display: block;
    font-size: 14px;
    font-weight: normal
    ;
}
.privacyModal .modal-dialog {
    max-width: 847px;
}