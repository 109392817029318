@import "../../assets/scss/variables.scss";

:focus,
:focus-visible {
  box-shadow: none !important;
  outline: 0 !important;
}

.filterWrap {
  .innerWrap {
    max-width: 317px;
    position: fixed;
    z-index: 2;
    width: 100%;
    right: 100%;
    background-color: $BG_WHITE;
    height: 100vh;
    transition: 0.3s ease;
  }

  &.active {
    .innerWrap {
      right: calc(100% - 317px);
      overflow-x: auto;
      padding-bottom: 30px;
    }
  }

}


.map {
  width: 100% !important;
  height: calc(100vh - 60px) !important;
}

.mainWrap {
  width: 100%;
  transition: 0.3s ease;
  position: relative;
  .no_data {
    text-align: center;
    font-family: $SFPRODISPLAY;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 42px;
    color: $TEXT_PURPLE;
    width: 100%;
    padding-top: 25px;

    p {
      margin-top: 10px;
    }
  }

}

.mainWrap.move {
  margin-left: 317px;
  width: calc(100% - 317px) !important;
}

.mapWrap {
  padding-top: 60px;
}

.mapWrap.hide {
  display: none;
}

.listVieWrap.hide {
  display: none;
}

.orange {

  &>.icon,
  .category {
    color: $ICON_ORANGE;
  }

  .color-icon {
    color: $ICON_ORANGE;
  }
}

.red {

  &>.icon,
  .category {
    color: $ICON_RED;
  }

  .color-icon {
    color: $ICON_RED;
  }
}

.grey {

  &>.icon,
  .category {
    color: $ICON_GREY;
  }

  .color-icon {
    color: $ICON_GREY;
  }
}

.violet {

  &>.icon,
  .category {
    color: $ICON_VIOLET;
  }

  .color-icon {
    color: $ICON_VIOLET;
  }
}

.blue {

  &>.icon,
  .category {
    color: $ICON_BLUE;
  }

  .color-icon {
    color: $ICON_BLUE;
  }
}

.yellow {

  &>.icon,
  .category {
    color: $ICON_YELLOW;
  }

  .color-icon {
    color: $ICON_YELLOW;
  }
}

.green {

  &>.icon,
  .category {
    color: $ICON_GREEN;
  }

  .color-icon {
    color: $ICON_GREEN;
  }
}

.eventBox {
  width: 32.4%;
  padding: 24px;
  display: inline-block;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  background-color: $BG_WHITE;

  &:nth-child(3n) {
    margin-right: 0;
  }

  &>.icon {
    font-size: 30px;
    margin-right: 10px;
  }

  h2 {
    color: $TEXT_TITLE;
    margin-bottom: 14px;
    align-items: center;
    display: flex;
    padding-top: 7px;
    max-width: 300px;
    word-break: break-word;
  }

  .cardContent {
    position: relative;
    width: calc(100% - 42px);
    max-width: 420px;
    font-size: 14px;
    color: $TEXT_TITLE;
    display: inline-block;
    vertical-align: top;

    span.innerTitle {
      color: $TEXT_YELLOW;
    }

    .eventTop {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $BORDER_BOTTOM;
    }

    .timing {
      margin-bottom: 8px;

      span {
        color: $TEXT_YELLOW;
      }
    }

    .category {
      font-weight: 400;
      font-size: 16px;
      max-width: 200px;
    }
  }
}

.mainWrap {
  &.move {
    .listVieWrap {
      .eventBox {
        width: 32.4%;
      }
    }
  }
}

.distance {
  background: #ececec;
  border-radius: 18px;
  position: absolute;
  right: 0;
  padding: 7px 12px;
  font-size: $FONT_14;
}

.btn-wrap {
  margin-top: 16px;

  button,
  a {
    padding: 10px 24px;
    border-radius: 20px;
    font-size: 12px;

    &.active,
    a {
      background-color: $BG_YELLOW !important;
    }

    &:first-child {

      &:hover,
      &:focus {
        background-color: $BG_WHITE;
        color: $TEXT_TITLE;
      }

    }

    // &:not(:first-child):hover,
    // &:not(:first-child):focus,
    // a {
    //   background: #fec242;
    //   color: #fff;
    // }

    span {
      margin-right: 6px;
    }

    .directions {
      margin-right: 0 !important;
    }
  }

  button:first-child,
  a {
    margin-right: 8px;
    background: $BG_BLUE;
    border-color: $BG_BLUE;
  }

  button:not(:first-child) {
    border: 1px solid $BG_YELLOW;
    background-color: transparent;
    color: $TEXT_PURPLE;
  }

  button:nth-child(3),
  a {
    margin-left: 8px;
    padding: 10px 15px;

    span {
      margin-right: 6px;
    }
  }

  .isbookmarked {
    background: #fec242 !important;
    color: $TEXT_WHITE !important;
  }


}

.modal-dialog {
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.05);
  border-radius: 25px;
}

.modal-title {
  margin-top: 44px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.modal-header button.btn-close {
  position: absolute;
  right: 29px;
  top: 29px;
  font-size: 14px;
  color: #3a4468;
  opacity: 1;
}

.modal-header {
  border-bottom: none;
  padding: 16px 24px 0;
}

.modal-body {
  padding: 16px 24px;
}

.reCenter {
  box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
  background: $BG_WHITE;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  position: fixed;
  right: 40px;
  bottom: 80px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
}

.download {
  box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
  background: $BG_GREY !important;
  border-radius: 50px;
  width: 48px;
  height: 48px;
  position: fixed;
  color: $TEXT_WHITE;
  right: 40px;
  bottom: 80px;
  line-height: 38px;
  text-align: center;
  border-color: $BG_GREY !important;

  &:hover &:active &:focus {
    background: $BG_GREY !important;
    border-color: $BG_GREY !important;
  }

}

.listVieWrap {
  &>div {
    cursor: pointer;
  }
}

.viewWrap {
  position: absolute;
  top: 80px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  background: #f3f3f6;
  box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.2);
  border-radius: 8px;
  padding: 4px;

  button {
    color: $TEXT_GREY;
    border: none;
    background: $BG_WHITE;
    height: 40px;

    &:hover,
    &:focus {
      color: $TEXT_PURPLE;
      background: $BG_WHITE;
    }
  }

  button.mapview-btn {
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid;
  }

  button.listview-btn {
    border-radius: 0px 8px 8px 0px;
  }

  button.active {
    background: $BG_PURPLE !important;
    color: $TEXT_WHITE !important;
  }
}

@media (max-width: 1680px) {
  .eventBox {
    width: 32%;
  }

  .mainWrap {
    &.move {
      .listVieWrap {
        .eventBox {
          width: 32%;
        }
      }
    }
  }
}

@media (max-width: 1430px) {
  .eventBox {
    &>.icon {
      font-size: 24px;
      display: block;
      margin-bottom: 10px;
    }

    .cardContent {
      width: 100%;
    }

    h2 {
      align-items: start;
      flex-direction: column;
    }
  }

  .distance {
    position: static;
    margin-top: 10px;
  }
}

@media (max-width: 1400px) {
  .filterWrap {
    &.active {
      &+.mainWrap {
        &.move {
          .eventBox {
            width: 31%;
          }
        }
      }
    }
  }

  .btn-wrap {
    button {
      padding: 10px 15px;
    }
  }
}

@media (max-width: 1200px) {
  .filterWrap {
    &.active {
      &+.mainWrap {
        &.move {
          .eventBox {
            width: 47.5%;

            &:nth-child(3n) {
              // margin-right: 20px;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .eventBox {
    width: 31%;
    padding: 18px;
  }
}

@media (max-width: 940px) {
  .eventBox {
    width: 48%;

    &:nth-child(3n) {
      // margin-right: 15px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .btn-wrap {
    button {
      padding: 8px 18px;
    }
  }

  .innerWrap {
    max-width: 277px;
  }

  .filterWrap {
    &.active {
      .innerWrap {
        right: calc(100% - 277px);
        max-width: 277px;
      }
    }
  }

  .mainWrap.move {
    margin-left: 277px;
    width: calc(100% - 277px) !important;
  }

  .map.active {
    margin-left: 277px;
    width: calc(100% - 277px) !important;
  }

  .filterWrap {
    &.active {
      &+.mainWrap {
        &.move {
          .eventBox {
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }
  }

}

@media print {

  pre,
  blockquote {
    page-break-inside: avoid;
  }
}

.showData {
  width: 100%;
  height: auto;
  position: fixed;
  top: -100%;
  right: -100%;
  pointer-events: none;
  z-index: -1;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 28px 0 20px;
  page-break-inside: avoid;

  .titleTable {
    .service {
      color: #3A4468;
      font-weight: 600;
      font-size: 28px;
      padding: 15px 15px 15px 15px;
    }
  }

  .showTable {
    width: 100%;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;

    .secondTable {
      width: 100%;
      height: auto;

      .categoryList {
        vertical-align: top;
        margin-bottom: 24px !important;
        padding: 0 10px;
        width: 25%;
        word-break: break-all;
        .category {
          margin-top: 10px;
          color: #FF9800;
          font-size: 18px;
          padding-bottom: 20px;
          font-weight: 400;
        }
        .name {
          margin-top: 10px;
          color: #3A4468;
          font-size: 20px;
          padding-bottom: 20px;
          font-weight: 600;
        }
        .description {
          margin-top: 10px;
          color: #838AA3;
          word-break: break-word;
          font-size: 14px;
          padding-bottom: 20px;
          font-weight: 400;
          max-width: 400px;
        }
        &.categoryList1 {
          // padding-left: 20px;
  
          .serviceTime {
            font-size: 20px;
            font-weight: 700;
            color: #3A4468;
            padding-bottom: 20px;
          }
  
          .serviceData {
            font-size: 20px;
            font-weight: 400;
            color: #3A4468;
            padding-bottom: 100px;
            width: 300px;
  
            .isActive {
              color: #FF9800;
            }
  
            .serviceTiming {
              padding: 24px 0px;
  
              .serviceDay {
                padding-right: 10px;
                width: 300px;
              }
  
              .isActive.serviceDay {
                padding-left: 10px;
              }
            }
          }
        }
        &.categoryList2 {
          .eventTime {
            font-size: 20px;
            font-weight: 700;
            color: #3A4468;
            padding-bottom: 10px;
          }
  
          .eventData {
            font-size: 20px;
            font-weight: 400;
            color: #3A4468;
            padding-bottom: 10px;
            margin: 5px;
            width: 200px;
          }
        }
        &.categoryList3{
          background: rgba(236, 236, 236, 0.25);
          max-width: 400px;
          width: 25%;
          &.locationTime{
            max-width: 400px;
            .location {
              font-size: 24px;
              color: #3A4468;
              font-weight: 400;
              padding-bottom: 10px;
    
              span {
                font-weight: 700;
                padding-right: 5px;
              }
            }
          }
          .location {
            font-size: 24px;
            color: #3A4468;
            font-weight: 400;
            padding-bottom: 10px;
  
            span {
              font-weight: 700;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
}
// @page {
//   margin: 10mm 10mm 10mm 10mm;
// }