@import "../../assets/scss/variables.scss";

.offcanvas-header {
  .btn-close {
    left: 40px;
    position: absolute;
    padding: 2px;
    top: 25px;
    width: 14px;
  }
}

.bottom-border {
  border-bottom: 1px solid $BG_LIGHTGREY;

  &:not(:first-child) {
    .icon {
      font-size: 20px;
      position: relative;
      color: $TEXT_PURPLE;
      opacity: 0.5;
      margin-right: 13px;
      position: relative;
      top: 3px;
    }
  }

  .accordion-header {
    .icon {
      top: 0;
    }
  }

  .social {
    .icon {
      margin-right: 24px;
    }
  }
}

.detialBox>div:empty {
  display: none;
}

.accordion-item {
  h2 {
    button {
      background: transparent !important;
      color: inherit !important;
      box-shadow: none !important;
    }
  }
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.offcanvas {
  width: 475px !important;
  padding: 0;

  .offcanvas-body {
    position: relative;
    padding: 0 40px;

    .detialBox {

      .time>* {
        display: inline-block;
      }

      .accordion-body {
        div {
          span:nth-child(2) {
            width: calc(100% - 50px);
          }
        }
      }
    }

    span {
      margin-right: 5px;
    }

    span.isActive {
      color: green;
    }
  }

  .eventTop {
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: $TEXT_TITLE;
      margin-top: 20px;
    }

    .topLine {
      display: flex;
      align-items: center;
      position: relative;

      &>.icon {
        font-size: 20px;
      }

      .distance {
        margin-top: 0;
        position: absolute;
      }

      span.category {
        margin-left: 3px;
      }
    }

    .address {
      font-size: 14px;
      line-height: 17px;
      color: $TEXT_TITLE;

      span {
        margin-right: 5px;
      }
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      margin-top: 16px;
      color: $TEXT_GREY;
    }
  }
}

.detialBox {
  &>div {
    padding: 25px 0;
  }

  .accordion-header {
    button {
      padding: 0;
      background: transparent !important;
      color: inherit !important;
      box-shadow: none !important;
      font-size: 14px;

      div {
        span {
          width: auto;
          display: inline-block;
        }
      }

      span:last-child {
        margin-left: 10px;
        color: $TEXT_YELLOW;
      }
    }
  }

  .accordion-body {
    padding-left: 32px;
    padding-bottom: 0;
    padding-top: 11px;

    div {
      font-size: 14px;
      margin-bottom: 11px;

      span {
        width: 40px;
        display: inline-block;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

  }
}

.pins {
  margin: 14px 0 5px;

  div {
    padding: 5px 16px 8px;
    line-height: 1;
    border: 1px solid $BG_LIGHTGREY;
    display: inline-block;
    font-size: 14px;
    border-radius: 50px;
    margin-top: 10px;

    span {
      font-size: 18px;
      position: relative;
      top: 2px;
    }

    &:first-child {
      margin-right: 6px;
    }

    .nameMap {
      font-size: 15px;
      display: flex;

      .text_name{
        margin: 0;
        font-family: $SFPRODISPLAY;
      }

    }
  }
}

.showViewData {
  width: 100%;
  height: auto;
  position: fixed;
  top: -100%;
  right: -100%;
  pointer-events: none;
  z-index: -1;
  font-family: Arial,
    Helvetica,
    sans-serif;
  padding: 0 28px 0 20px;


  .showTable {
    width: 100%;
    height: auto;


    .titleTable {
      .service {
        color: #3A4468;
        font-weight: 600;
        font-size: 32px;
        padding: 30px 30px 30px 30px;
      }
    }

    .secondTable {
      width: 100%;
      height: auto;
      margin-left: 15px;
      margin-right: 15px;

      .categoryList {
        vertical-align: top;
        margin-bottom: 24px;
        padding: 0 15px;
        width: 25%;
        word-break: break-all;

        .category {
          margin-top: 10px;
          color: #FF9800;
          font-size: 24px;
          // line-height: 14px;
          padding-bottom: 10px;
          font-weight: 400;
        }

        .name {
          margin-top: 10px;
          color: #3A4468;
          font-size: 32px;
          padding-bottom: 10px;
          font-weight: 600;
        }

        .description {
          margin-top: 10px;
          color: #838AA3;
          word-break: break-word;
          font-size: 24px;
          padding-bottom: 16px;
          font-weight: 400;
          max-width: 400px;
        }
      }

      .categoryList1 {
        padding-left: 20px;
        vertical-align: top;
        margin-bottom: 24px;

        .serviceTime {
          font-size: 24px;
          font-weight: 700;
          color: #3A4468;
          padding-bottom: 10px;
        }

        .serviceData {
          font-size: 24px;
          font-weight: 400;
          color: #3A4468;
          padding-bottom: 10px;
          width: calc(100% - 50px);

          .isActive {
            margin-left: 5px;
            color: #FF9800;
          }

          .serviceTiming {
            padding: 15px 0px;

            .serviceDay {
              padding-right: 10px;
            }

            .isActive.serviceDay {
              padding-left: 10px;
            }
          }
        }

        .eventDataStyle {
          display: flex;
          font-size: 24px;

          .icon.icon-calendar {
            margin-top: 3px;
            margin-right: 13px;
          }
          div{
            .isActive{
                  color: green;
                  margin-left: 5px;
            }
          }
        }
      }

      .categoryList2 {
        vertical-align: top;
        margin-bottom: 24px;

        .eventTime {
          font-size: 24px;
          font-weight: 700;
          color: #3A4468;
          padding-bottom: 10px;
        }

        .eventData {
          font-size: 24px;
          font-weight: 400;
          color: #3A4468;
          padding-bottom: 10px;
          margin-top: 10px;

          .mapName {
            margin-top: 10px !important;
          }
        }
      }

      .categoryList3 {

        vertical-align: top;
        background: rgba(236, 236, 236, 0.25);
        margin-bottom: 24px;
        max-width: 400px;
        padding-left: 15px;
        padding-right: 15px;
        width: 25%;
        word-break: break-all;


        .location {
          font-size: 24px;
          color: #3A4468;
          font-weight: 400;
          padding-bottom: 10px;

          span {
            font-weight: 700;
            padding-right: 5px;
          }
        }
      }

      .categoryList3.locationTime {
        max-width: 400px;
      }
    }

  }
}