//BG COLORS
$BG_WHITE: #ffffff;
$BG_PURPLE: #320e3b;
$BG_BLUE:#3a4468;
$BG_LIGHTGREY: #ececec;
$BG_GREY: #838aa3;
$BG_DISABLED: #d3dce4;
$BG_SUCCES: #3e9932;
$BG_ACTIVE: #143964;
$BG_BORDER: #d8d9dd;
$BG_RED: #d03e3e;
$BG_BOX_BORDER: #cbbba2;
$BG_BORDER_DROP: #bdcbd6;
$BORDER_BOTTOM: #ececec;
$BG_YELLOW_LIGHT: #fff9ec;
$BG_YELLOW: #fec242;
$BG_YELLOW2: #ffd60a;
$BG_BOX_BOTTOM_BORDER: #ebebeb;
$CALENDAR_BORDER: #cbbba2;
$BG_TOGGELE: #c5e0c2;
$BG_GREY2: #f3f3f6;


// Text Colors
$TEXT_WHITE: #ffffff;
$TEXT_PURPLE: #320e3b;
$TEXT_GREY: #838aa3;
$TEXT_TITLE: #3a4468;
$TEXT_LIGHTGREY: #ececec;
$TEXT_TRIADIC: #c4c6cc;
$TEXT_PLACEHOLDER: #838aa3;
$TEXT_SUCCESS: #3e9932;
$TEXT_RED: #d03e3e;
$TEXT_DISABLED: #91a8bb;
$TEXT_ACTIVE: #143964;
$TEXT_YELLOW: #fec242;
$TEXT_DARK_YELLOW: #977645;

// Font Size
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_26: 26px;
$FONT_28: 28px;
$FONT_32: 32px;
$FONT_36: 36px;

$SFPRODISPLAY: SF Pro Display;

// icon colors

$ICON_ORANGE: #ff9800;
$ICON_RED: #e91e63;
$ICON_GREY: #bdb9b9;
$ICON_VIOLET: #3f51b5;
$ICON_BLUE: #2196f3;
$ICON_YELLOW: #f5d52a;
$ICON_GREEN: #009688;

// .orange {
//   .color-icon {
//     color: $ICON_ORANGE;
//   }
// }
// .red {
//   .color-icon {
//     color: $ICON_RED;
//   }
// }
// .grey {
//   .color-icon {
//     color: $ICON_GREY;
//   }
// }
// .violet {
//   .color-icon {
//     color: $ICON_VIOLET;
//   }
// }
// .blue {
//   .color-icon {
//     color: $ICON_BLUE;
//   }
// }
// .yellow {
//   .color-icon {
//     color: $ICON_YELLOW;
//   }
// }
// .green {
//   .color-icon {
//     color: $ICON_GREEN;
//   }
// }

// .orange-text {
//   color: $ICON_ORANGE;
// }
// .red-text {
//   color: $ICON_RED;
// }
// .grey-text {
//   color: $ICON_GREY;
// }
// .violet-text {
//   color: $ICON_VIOLET;
// }
// .blue-text {
//   color: $ICON_BLUE;
// }
// .yellow-text {
//   color: $ICON_YELLOW;
// }
// .green-text {
//   color: $ICON_GREEN;
// }
