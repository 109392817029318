@import "../../assets/scss/variables.scss";

.contactModal {
  .modal-dialog {
    max-width: 1164px;
    .modal-body {
      padding-bottom: 24px;
    }
  }
}
.contactModal.map_padding {
  padding: 0 20px;
}
.boxWrap {
  max-width: 870px;
  left: 50%;
  position: absolute;
  bottom: 44px;
  transform: translateX(-50%);
  width: 100%;
  .contactBox {
    background: $BG_WHITE;
    box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.05);
    border-radius: 11px;
    padding: 16px;
    .leftside {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $BG_BLUE;
      margin-right: 35px;
      span {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #bbbbbb;
        margin-top: 4px;
      }
    }
    .icon {
      font-size: 27px;
    }
  }
}
.contactMap {
  width: 100% !important;
  height: 58vh !important;
}
a.directionIcon {
  background: $BG_BLUE;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 40px;
  color: $TEXT_WHITE;
  font-size: 10px;
  position: absolute;
  right: 44px;
  bottom: 44px;
}

@media (max-width: 1050px) {
  .boxWrap {
    max-width: 320px;
    left:44px;
    transform: translate(0);
    .contactBox {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
}
