@import "./assets/scss/variables.scss";

@font-face {
  font-family: $SFPRODISPLAY;
  src: url('./assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
      url('./assets/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot?xpdi3k');
  src:  url('./assets/fonts/icomoon.eot?xpdi3k#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?xpdi3k') format('truetype'),
    url('./assets/fonts/icomoon.woff?xpdi3k') format('woff'),
    url('./assets/fonts/icomoon.svg?xpdi3k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $BG_GREY;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $BG_PURPLE;
}

body{
  font-family: SF Pro Display;
  font-weight: 400;
}
a{
  text-decoration: none;
  color: $TEXT_GREY;
}
a:hover,a:focus{
  color: $TEXT_YELLOW;
}
ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

.icon-info_circle_outline:before {
  content: "\e91a";
}
.icon-bin:before {
  content: "\e919";
}
.icon-calendar:before {
  content: "\e910";
}
.icon-call:before {
  content: "\e912";
}
.icon-facebook:before {
  content: "\e913";
}
.icon-instagram:before {
  content: "\e914";
}
.icon-link:before {
  content: "\e915";
}
.icon-linkedin:before {
  content: "\e916";
}
.icon-mail:before {
  content: "\e917";
}
.icon-twitter:before {
  content: "\e918";
}
.icon-marker:before {
  content: "\e90f";
}
.icon-Target:before {
  content: "\e90d";
}
.icon-download:before {
  content: "\e90e";
}
.icon-event:before {
  content: "\e902";
}
.icon-service-pin:before {
  content: "\e903";
}
.icon-bookmark:before {
  content: "\e900";
}
.icon-dots:before {
  content: "\e901";
}
.icon-home:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e905";
}
.icon-supporters:before {
  content: "\e906";
}
.icon-privacy-policy:before {
  content: "\e907";
}
.icon-filter:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e90a";
}
.icon-bookmark-outline:before {
  content: "\e90b";
}
.icon-coolicon:before {
  content: "\e90c";
}
.icon-activity-pin:before {
  content: "\e911";
}