@import "../../assets/scss/variables.scss";

.banner {
  background: url(../../assets/images/banner.jpg);
  background-size: cover;
  padding: 25px 0;
}

h1,
h2 {
  font-size: $FONT_16;
  font-weight: 600;
  line-height: 19px;
  color: $TEXT_PURPLE;
}

.filterWrap {
  .btn-primary {
    background-color: $TEXT_PURPLE;
    color: $BG_YELLOW_LIGHT;
    border-radius: 0;
    line-height: 2.5;
    height: 48px;
    width: 48px;
    border: none;
    position: fixed;
    right: -48px;
    top: 20px;
    left: 0;
    top: 80px;
    z-index: 2;
    transition: 0.3s ease;
  }

  &.active {
    .btn-primary {
      left: 317px;
    }
  }

  .innerWrap {
    .filterTypeWrap {
      padding: 0 20px;

      h1 {
        margin-top: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid $BG_LIGHTGREY;

        span {
          background: $BG_YELLOW2;
          padding: 4px;
          font-weight: 400;
          font-size: $FONT_12;
          line-height: 14px;
          text-align: center;
          border-radius: 4px;
          position: relative;
          top: -2px;
          margin-left: 6px;
        }
      }

      .categories {
        border-bottom: 1px solid $BG_LIGHTGREY;

        h2 {
          margin-bottom: 30px;
          margin-top: 20px;
          color: $TEXT_TITLE;
        }

        ul {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            a {
              font-weight: lighter;
            }

            span.color {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: inline-block;
            }
          }
        }
      }

      .domains {
        padding-bottom: 20px;
        border-bottom: 1px solid $BG_LIGHTGREY;

        h2 {
          margin: 20px 0;
        }

        .checkboxMain:last-child {
          margin-top: 15px;
        }
      }

      .onGoing {
        display: flex;
        justify-content: space-between;
        background: #f3f3f6;
        border-radius: 25px;
        margin-top: 20px;
        padding: 12px 16px;
        color: $TEXT_TITLE;
        font-weight: lighter;
        font-size: $FONT_14;

        .title {
          padding: 0;
          margin: 3px 0 0 0;
          font-family: 'SF Pro Display';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $TEXT_TITLE;
        }

        // .form-switch {
        //   background: #f3f3f6;
        //   border-radius: 25px;
        //   line-height: 48px;
        //   display: flex;
        //   justify-content: space-between;
        //   padding: 0 16px;
        //   align-items: center;
        //   flex-direction: row-reverse;
        //   color: $TEXT_TITLE;
        //   font-weight: lighter;
        //   font-size: $FONT_14;

        //   .form-check-input {
        //     width: 41px;
        //     height: 21px;
        //     margin-left: 0;
        //     background-size: 22px;
        //     position: relative;
        //     background-color: $BG_LIGHTGREY;
        //     border: none;

        //     &:focus {
        //       background-image: url(../../assets/images/circle.svg);
        //       background-color: $BG_LIGHTGREY;
        //       border-color: #bfbfbf;
        //     }

        //     &:checked {
        //       background-color: #32d74c;
        //       border-color: #32d74c;
        //       background-image: url(../../assets/images/circle.svg);
        //     }

        //     &:before {
        //       content: "";
        //       background-image: url(../../assets/images/circle.svg);
        //       position: absolute;
        //       width: 30px;
        //       right: 14px;
        //       height: 30px;
        //       top: -5px;
        //       background-repeat: no-repeat;
        //       z-index: 1;
        //       transition: 0.3s;
        //     }

        //     &:checked:before {
        //       content: "";
        //       right: -6px;
        //       background-repeat: no-repeat;
        //     }
        //   }
        // }
      }
    }
  }
}

@media (max-width: 940px) {
  .filterWrap {
    &.active {
      .btn-primary {
        left: 277px;
      }
    }
  }
}
@media only screen
    and (min-device-width : 375px) {
      .filterWrap {
        .categories {
          border-bottom: 1px solid $BG_LIGHTGREY;

          h2 {
            margin-bottom: 10px !important;
            margin-top: 10px !important;
            color: $TEXT_TITLE;
          }

        }
        .domains {
          padding-bottom: 10px !important;
          border-bottom: 1px solid $BG_LIGHTGREY;

          h2 {
            margin: 8px 0 !important;
          }

          .checkboxMain:last-child {
            margin-top: 10px !important;
          }
        }
        .onGoing {
          display: flex;
          justify-content: space-between;
          background: #f3f3f6;
          border-radius: 25px;
          margin-top: 10px !important;
          padding: 10px 15px !important;
          color: $TEXT_TITLE;
          font-weight: lighter;
          font-size: $FONT_14;

          .title {
            padding: 0;
            margin: 3px 0 0 0;
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $TEXT_TITLE;
          }
        }
      }
    }